import cy from 'i18n/locale/cy/cy.json'
import cy_global_exercises from 'i18n/locale/cy/cy_global_exercises.json'
import cy_global_programs from 'i18n/locale/cy/cy_global_programs.json'
import en_countries from 'i18n/locale/en/en_countries.json'

export default {
  ...cy,
  ...cy_global_exercises,
  ...cy_global_programs,
  ...en_countries,
}
