/*global process*/

import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import sheet from './style.scss'
import PropTypes from 'prop-types'

import Header from 'app/root/header/Header'
import Sidebar from 'app/root/sidebar/Sidebar'
import Main from 'app/root/main/container/Main'
import Footer from 'app/root/footer/Footer'
import ActionFooter from 'app/root/action-footer/ActionFooter'
import PrivateRoute from 'app/root/private-route/container/PrivateRoute'
import OnRouteChange from 'app/root/on-route-change/container/OnRouteChange'
import FadeRoute from 'app/root/fade-route/component/FadeRoute'
import { isAndroidWebApp, isIOSWebApp } from 'util/util'

// public
import AppInfo from 'app/public/app-info/component/AppInfo'
import Join from 'app/public/join/container/Join'
import SignIn from 'app/public/sign-in/SignIn'
import GymInviteLink from 'app/public/gym-invite-link/container/GymInviteLink'
import ExportUserData from 'app/public/export-user-data/ExportUserData'
import PasswordForgotten from 'app/public/password-forgotten/container/PasswordForgotten'
import PasswordChange from 'app/public/password-change/container/PasswordChange'

// protected
import Protected from 'app/protected/container/Protected'
import Unauthorized from 'app/root/unauthorized/container/Unauthorized'

// Error boundary
import ErrorBoundary from 'app/common/error-boundary/component/ErrorBoundary'

import { Grid, Sidebar as SidebarComponent } from 'semantic-ui-react'

import ReloadCheck from 'app/common/reload-check/ReloadCheck'
const commitHash = process.env.COMMIT_ID.trim()

import { homePaths } from 'app/protected/common/constants/routes'

import { connect } from 'react-redux'

import 'moment/locale/de'
//import 'moment/locale/en' // doesn't exist or is needed
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'
import 'moment/locale/pt'
import 'moment/locale/sv'
import 'moment/locale/ja'

//Countries that we have gyms in but no lang support
import 'moment/locale/da'
import 'moment/locale/nb'
import 'moment/locale/fi'

import i18n from 'i18n/i18n'
import { getLanguage } from 'app/protected/common/LanguageChooser'
import { setLanguageAndTimeFormat, isNotUndefinedAndNull } from 'util/util'


const NoMatch = ({ location }) => (
  <div>
    <h3>
      No match for resource
      <code>{location.pathname}</code>
    </h3>
  </div>
)

const Root = (props) => {
  const { refreshInterval, refreshUser, getUser, savedLanguage, showFooter, history, location, siteLangCode, setSiteLang } = props

  useEffect(() => {
    if (refreshUser) getUser()

    // let refreshTimer
    // if (refreshInterval) {
    //   refreshTimer = setInterval(() => {
    //     getUser()
    //   }, refreshInterval)
    // }
    const statisticsLocale = (location.pathname.includes("gyms") && location.pathname.includes("statistics") && siteLangCode) ? siteLangCode : null
    if (savedLanguage && savedLanguage.value != i18n.language) {
      // if there is no saved language in user settings
      setLanguageAndTimeFormat(savedLanguage.value, statisticsLocale)
    } else {
      setLanguageAndTimeFormat(i18n.language, statisticsLocale)
    }
    if (statisticsLocale) {
      setSiteLang(statisticsLocale)
    }

    // return () => {
    //   clearInterval(refreshTimer)
    // }
  }, [savedLanguage, siteLangCode])

  const isStartPage = (_id) => {
    const { pathname } = props.location
    const path = homePaths.ME()
    return pathname.replace(/\//g, '') == path.replace(/\//g, '')
  }

  return (
    <ErrorBoundary>
      <OnRouteChange location={props.location}>
        <div className={sheet.root}>
          <Header />
          <ActionFooter history={history} location={location} />
          <div id="custom-prompt"></div>

          {props.isAuthorized && <Sidebar />}

          <SidebarComponent.Pushable style={{ overscrollBehavior: "none" }} onClick={() => props.closeMenu()}>
            <SidebarComponent.Pusher dimmed={props.isOpen}>
              <main className={`${sheet.main}`}>
                <Grid centered padded>
                  <Grid.Row verticalAlign="middle" textAlign="center">
                    <Grid.Column
                      mobile="16"
                      tablet="16"
                      computer="12"
                      largeScreen="11"
                      widescreen="9"
                    >
                      <Switch>
                        <Route
                          exact
                          path="/index.html"
                          render={(props) => <Redirect to="/" />}
                        />
                        <FadeRoute exact path="/" component={Main} />
                        <FadeRoute
                          exact
                          path={homePaths.JOIN()}
                          component={Join}
                        />
                        <FadeRoute
                          exact
                          path={homePaths.SIGNIN()}
                          component={SignIn}
                        />
                        <FadeRoute
                          exact
                          path={homePaths.EXPORT_USER_DATA()}
                          component={ExportUserData}
                        />
                        <FadeRoute
                          exact
                          path={homePaths.GYM_INVITE_LINK()}
                          component={GymInviteLink}
                        />
                        <FadeRoute
                          exact
                          path={homePaths.GYM_INVITE_MISSING_LINK()}
                          component={GymInviteLink}
                        />
                        <FadeRoute
                          exact
                          path={homePaths.GYM_INVITE_LINK_APPSTORE()}
                          component={GymInviteLink}
                        />
                        <FadeRoute
                          exact
                          path={homePaths.PASSWORD_FORGOTTEN()}
                          component={PasswordForgotten}
                        />

                        <Route
                          exact
                          path={homePaths.PASSWORD_CHANGE()}
                          render={(props) => <Redirect to="/" />}
                        />
                        <FadeRoute
                          exact
                          path={`${homePaths.PASSWORD_CHANGE()}/:id`}
                          component={PasswordChange}
                        />

                        <FadeRoute
                          exact
                          path={homePaths.APP_INFO()}
                          component={AppInfo}
                        />
                        <PrivateRoute
                          path={homePaths.PROTECTED()}
                          wrapped={Protected}
                        />
                        <FadeRoute
                          exact
                          path={homePaths.UNAUTHORIZED()}
                          component={Unauthorized}
                        />
                        <FadeRoute component={NoMatch} />
                      </Switch>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <div className={`${sheet.footerPush}`} />
              </main>
              {showFooter ? <Footer className={sheet.footer} /> : <div style={{ height: "80px" }} />}
            </SidebarComponent.Pusher>
          </SidebarComponent.Pushable>
        </div>
      </OnRouteChange>
      <ReloadCheck commitHash={commitHash} />
    </ErrorBoundary>
  )
}

Root.defaultProps = {}

Root.propTypes = {
  refreshInterval: PropTypes.number,
  refreshUser: PropTypes.bool.isRequired,
  getUser: PropTypes.func.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
  showFooter: PropTypes.bool.isRequired,
  siteLangCode: PropTypes.string,
  setSiteLang: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const { userReducer, actionFooterReducer } = state
  const { data } = userReducer
  const { settings } = data
  const savedLanguage = getLanguage(settings)
  const { leftText, rightText, extraRightText } = actionFooterReducer

  let showFooter = !(isIOSWebApp || isAndroidWebApp)
  !isNotUndefinedAndNull(leftText) ? null : showFooter = false
  !isNotUndefinedAndNull(rightText) ? null : showFooter = false
  !isNotUndefinedAndNull(extraRightText) ? null : showFooter = false

  return {
    savedLanguage,
    showFooter,
  }
}

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Root)
