import zhHant from 'i18n/locale/zh-Hant/zh-Hant.json'
import zhHant_global_exercises from 'i18n/locale/zh-Hant/zh-Hant_global_exercises.json'
import zhHant_global_programs from 'i18n/locale/zh-Hant/zh-Hant_global_programs.json'
import en_countries from 'i18n/locale/en/en_countries.json'

export default {
  ...zhHant,
  ...zhHant_global_exercises,
  ...zhHant_global_programs,
  ...en_countries,
}
