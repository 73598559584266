import it from 'i18n/locale/it/it.json'
import it_global_exercises from 'i18n/locale/it/it_global_exercises.json'
import it_global_programs from 'i18n/locale/it/it_global_programs.json'
import it_countries from 'i18n/locale/it/it_countries.json'

export default {
  ...it,
  ...it_global_exercises,
  ...it_global_programs,
  ...it_countries,
}
