import nl from 'i18n/locale/nl/nl.json'
import nl_global_exercises from 'i18n/locale/nl/nl_global_exercises.json'
import nl_global_programs from 'i18n/locale/nl/nl_global_programs.json'
import nl_countries from 'i18n/locale/nl/nl_countries.json'

export default {
  ...nl,
  ...nl_global_exercises,
  ...nl_global_programs,
  ...nl_countries,
}
