import ko from 'i18n/locale/kr/ko.json'
import ko_global_exercises from 'i18n/locale/kr/ko_global_exercises.json'
import ko_global_programs from 'i18n/locale/kr/ko_global_programs.json'
import en_countries from 'i18n/locale/en/en_countries.json'

export default {
  ...ko,
  ...ko_global_exercises,
  ...ko_global_programs,
  ...en_countries,
}
