import da from 'i18n/locale/da/da.json'
import da_global_exercises from 'i18n/locale/da/da_global_exercises.json'
import da_global_programs from 'i18n/locale/da/da_global_programs.json'
import en_countries from 'i18n/locale/en/en_countries.json'

export default {
  ...da,
  ...da_global_exercises,
  ...da_global_programs,
  ...en_countries,
}
