import pt from 'i18n/locale/pt/pt.json'
import pt_global_exercises from 'i18n/locale/pt/pt_global_exercises.json'
import pt_global_programs from 'i18n/locale/pt/pt_global_programs.json'
import pt_countries from 'i18n/locale/pt/pt_countries.json'

export default {
  ...pt,
  ...pt_global_exercises,
  ...pt_global_programs,
  ...pt_countries,
}
