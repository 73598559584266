import zhHans from 'i18n/locale/zh-Hans/zh-Hans.json'
import zhHans_global_exercises from 'i18n/locale/zh-Hans/zh-Hans_global_exercises.json'
import zhHans_global_programs from 'i18n/locale/zh-Hans/zh-Hans_global_programs.json'
import en_countries from 'i18n/locale/en/en_countries.json'

export default {
  ...zhHans,
  ...zhHans_global_exercises,
  ...zhHans_global_programs,
  ...en_countries,
}
