import en from 'i18n/locale/en/en.json'
import en_global_exercises from 'i18n/locale/en/en_global_exercises.json'
import en_global_programs from 'i18n/locale/en/en_global_programs.json'
import en_countries from 'i18n/locale/en/en_countries.json'

export default {
  ...en,
  ...en_global_exercises,
  ...en_global_programs,
  ...en_countries,
}
