import i18n from 'i18next'
// import { reactI18nextModule } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'

import translation_de from './locale/de/'
import translation_en from './locale/en/'
import translation_es from './locale/es/'
import translation_fr from './locale/fr/'
import translation_it from './locale/it/'
import translation_pt from './locale/pt/'
import translation_sv from './locale/sv/'
import translation_ja from './locale/ja/'
import translation_nl from './locale/nl/'
import translation_eu from './locale/eu/'
import translation_fi from './locale/fi/'
import translation_kr from './locale/kr/'
import translation_cy from './locale/cy/'
import translation_da from './locale/da/'
import translation_pl from './locale/pl/'
import translation_ru from './locale/ru/'
import translation_tr from './locale/tr/'
import translation_zh_Hans from './locale/zh-Hans/'
import translation_zh_Hant from './locale/zh-Hant/'

export const resources = {
  // ar: {
  //   translation: translation_ar,
  // },
  es: {
    translation: translation_es,
  },
  eu: {
    translation: translation_eu,
  },
  fi: {
    translation: translation_fi,
  },
  kr: {
    translation: translation_kr,
  },
  cy: {
    translation: translation_cy,
  },
  da: {
    translation: translation_da,
  },
  de: {
    translation: translation_de,
  },
  en: {
    translation: translation_en,
  },
  fr: {
    translation: translation_fr,
  },
  it: {
    translation: translation_it,
  },
  pt: {
    translation: translation_pt,
  },
  ja: {
    translation: translation_ja,
  },
  sv: {
    translation: translation_sv,
  },
  nl: {
    translation: translation_nl,
  },
  pl: {
    translation: translation_pl,
  },
  ru: {
    translation: translation_ru,
  },
  tr: {
    translation: translation_tr,
  },
  'zh-Hans': {
    translation: translation_zh_Hans,
  },
  'zh-Hant': {
    translation: translation_zh_Hant,
  },
}

export const supportedLanguages = [
  // {
  //   flag: 'de',
  //   code: 'ar',
  //   language: 'عربي',
  // },
  {
    flag: 'gb wls',
    code: 'cy',
    language: 'Cymraeg',
  },
  {
    flag: 'dk',
    code: 'da',
    language: 'Dansk',
  },
  {
    flag: 'de',
    code: 'de',
    language: 'Deutsch',
  },
  {
    flag: 'gb',
    code: 'en',
    language: 'English',
  },
  {
    flag: 'es',
    code: 'es',
    language: 'Español',
  },
  {
    flag: '',
    code: 'eu',
    language: 'Euskara',
  },
  {
    flag: 'fi',
    code: 'fi',
    language: 'Suomi',
  },
  {
    flag: 'fr',
    code: 'fr',
    language: 'Français',
  },
  // {
  //   flag: 'il',
  //   code: 'de',
  //   language: 'עִברִית',
  // },
  {
    flag: 'it',
    code: 'it',
    language: 'Italiana',
  },
  {
    flag: 'kr',
    code: 'kr',
    language: '한국어',
  },
  {
    flag: 'nl',
    code: 'nl',
    language: 'Nederlands',
  },
  {
    flag: 'pl',
    code: 'pl',
    language: 'Polski',
  },
  {
    flag: 'br',
    code: 'pt',
    language: 'Português',
  },
  {
    flag: 'ru',
    code: 'ru',
    language: 'Pусский',
  },
  {
    flag: 'se',
    code: 'sv',
    language: 'Svenska',
  },
  {
    flag: 'tr',
    code: 'tr',
    language: 'Türkçe',
  },
  {
    flag: 'cn',
    code: 'zh-Hans',
    language: '简体中文',
  },
  {
    flag: 'cn',
    code: 'zh-Hant',
    language: '繁體中文',
  },
  {
    flag: 'jp',
    code: 'ja',
    language: '日本語',
  },
]

const whitelist = Object.keys(resources)

i18n
  .use(detector)
  // .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    whitelist,
    fallbackLng: 'en',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
