import fr from 'i18n/locale/fr/fr.json'
import fr_global_exercises from 'i18n/locale/fr/fr_global_exercises.json'
import fr_global_programs from 'i18n/locale/fr/fr_global_programs.json'
import fr_countries from 'i18n/locale/fr/fr_countries.json'

export default {
  ...fr,
  ...fr_global_exercises,
  ...fr_global_programs,
  ...fr_countries,
}
