import fi from 'i18n/locale/fi/fi.json'
import fi_global_exercises from 'i18n/locale/fi/fi_global_exercises.json'
import fi_global_programs from 'i18n/locale/fi/fi_global_programs.json'
import en_countries from 'i18n/locale/en/en_countries.json'

export default {
  ...fi,
  ...fi_global_exercises,
  ...fi_global_programs,
  ...en_countries,
}
