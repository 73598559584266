import sv from 'i18n/locale/sv/sv.json'
import sv_global_exercises from 'i18n/locale/sv/sv_global_exercises.json'
import sv_global_programs from 'i18n/locale/sv/sv_global_programs.json'
import sv_countries from 'i18n/locale/sv/sv_countries.json'

export default {
  ...sv,
  ...sv_global_exercises,
  ...sv_global_programs,
  ...sv_countries,
}
