import es from 'i18n/locale/es/es.json'
import es_global_exercises from 'i18n/locale/es/es_global_exercises.json'
import es_global_programs from 'i18n/locale/es/es_global_programs.json'
import es_countries from 'i18n/locale/es/es_countries.json'

export default {
  ...es,
  ...es_global_exercises,
  ...es_global_programs,
  ...es_countries,
}
