import ja from 'i18n/locale/ja/ja.json'
import ja_global_exercises from 'i18n/locale/ja/ja_global_exercises.json'
import ja_global_programs from 'i18n/locale/ja/ja_global_programs.json'
import ja_countries from 'i18n/locale/ja/ja_countries.json'

export default {
  ...ja,
  ...ja_global_exercises,
  ...ja_global_programs,
  ...ja_countries,
}
