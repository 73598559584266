import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isMobile, isIOS, isAndroid } from 'react-device-detect'
import { Form, Icon, Header, Grid, Segment } from 'semantic-ui-react'
import LoadingMessage from 'app/common/loading-message/component/LoadingMessage'
import Badge from 'app/protected/roles/common/Badge'
import { getGymLogo } from 'util/util'
import advagymA from 'assets/images/advagym_a_white.svg'
import { mediumGrey } from 'app/protected/roles/util/colors'
import i18next from 'i18next'
import TemporaryBanner from 'app/protected/roles/common/TemporaryBanner'

//TO DO BEFORE LF LAUNCH - ADD CORRECT APP ID'S

export default function GymInviteLink(props) {
  const { gymLinkCode, getSite, ongoing, site, redirectToAppStore } = props
  const initialState = {
    redirectFrom: false,
    isOSDoReload: false,
  }
  const [{ redirectFrom, isOSDoReload }, setState] = useState(initialState)

  useEffect(() => {
    getSite(gymLinkCode)
    if (isIOS) {
      setTimeout(function () {
        setState((prevState) => ({ ...prevState, isOSDoReload: true }))
      }, 8000)
    }
  }, [])

  const iOSClipBoardText = window.location.href

  const textRef = useRef(null)

  const getInvertedDomain = () => {
    const currentUrlSplitt = window.location.href.split('/')
    if (currentUrlSplitt.length > 0) {
      const isDev = currentUrlSplitt.find(
        (i) => i === 'dev.trainer.lifefitness.com'
      )
      const isStag = currentUrlSplitt.find(
        (i) => i === 'stg.trainer.lifefitness.com'
      )
      const isProd = currentUrlSplitt.find(
        (i) => i === 'trainer.lifefitness.com'
      )
      if (isProd) return 'https://trainer.lifefitness.com'
      if (isStag) return 'https://stg.trainer.lifefitness.com'
      if (isDev) return 'https://dev.trainer.lifefitness.com'
    }
    return 'https://trainer.lifefitness.com'
  }

  const redirectToStore = (e) => {
    textRef.current && textRef.current.select()
    textRef.current && document.execCommand('copy')
    if (isIOS) {
      if (!redirectToAppStore) {
        if (gymLinkCode === '') {
          const newPath = getInvertedDomain() + '/gym-invite-link/appStore'
          window.location.replace(newPath)
        } else {
          const newPath =
            getInvertedDomain() +
            '/gym-invite-link/' +
            gymLinkCode +
            '/appStore'
          window.location.replace(newPath)
        }
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        redirectFrom: true,
      }))
      setTimeout(function () {
        setState(initialState)
      }, 1000)
    }
  }

  const faultyPlatform = () => {
    return (
      <div style={{ display: 'flex', flex: '1', justifyContent: 'center' }}>
        <Header as="h2" icon>
          <Icon name="mobile alternate" />
          {i18next.t('platform_not_supported')}
          <Header.Subheader>
            {i18next.t(
              'please_open_this_page_on_your_mobile_ios_or_android_device'
            )}
          </Header.Subheader>
        </Header>
      </div>
    )
  }

  const linkExpired = () => {
    return (
      <div style={{ display: 'flex', flex: '1', justifyContent: 'center' }}>
        <Header as="h2" icon>
          <Icon name="warning sign" />
          {i18next.t('link_has_expired_or_not_valid')}
          <Header.Subheader>
            {i18next.t('please_contact_your_gym_for_further_assistance')}
          </Header.Subheader>
        </Header>
      </div>
    )
  }

  const headerView = () => {
    const logo = site ? getGymLogo(site.media) : ''
    return (
      <Grid>
        <Grid.Row textAlign="center" style={{ paddingBottom: '0px' }}>
          <Grid.Column textAlign="center">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Badge
                type="coach"
                label={site ? site.name : 'TrainerConnect'}
                icon=""
                customIcon={logo === '' ? advagymA : ''}
                size="big"
                imageUrl={logo ? logo.url : ''}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row textAlign="center">
          <Grid.Column>
            <Header as="h2" icon>
              <div>{i18next.t('welcome_to')}</div>
              <div>{site ? site.name : 'TrainerConnect'}</div>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  const redirectView = () => {
    return (
      <>
        {site || gymLinkCode === '' || gymLinkCode.includes('appStore')
          ? headerView()
          : linkExpired()}

        <Segment
          onClick={redirectToStore}
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '15px',
            borderRadius: '4px',
          }}
        >
          <span>
            <Badge square={isAndroid} type="coach" customIcon={advagymA} />
          </span>

          <span
            style={{
              display: 'inline-flex',
              flexDirection: 'column',
              paddingLeft: '12px',
              flex: '1 0 0',
            }}
          >
            <div>
              <Header as="h4">{i18next.t('open_advagym_app')}</Header>
            </div>
            <div>
              <span style={{ color: mediumGrey }}>
                {i18next.t('press_here_to_get_started') + '!'}
              </span>
            </div>
          </span>
        </Segment>

        <Form>
          <textarea
            style={{
              resize: 'none',
              border: '0px',
              backgroundColor: '#f3f5f6',
              color: '#f3f5f6',
              cursor: 'unset',
              padding: '0px',
              overflowY: 'hidden',
              height: '1px',
            }}
            rows={1}
            readOnly
            ref={textRef}
            value={iOSClipBoardText}
          />
        </Form>
      </>
    )
  }

  if (ongoing === 'nothing') {
    if (isMobile) {
      if (isIOS) {
        if (!redirectFrom && !redirectToAppStore) {
          return redirectView()
        } else {
          if (redirectToAppStore) {
            window.location.replace(
              'itms-apps://apps.apple.com/us/app/life-fitness-connect-app/6468623712'
            )
            setTimeout(function () {
              redirectToStore()
            }, 1000)
          } else {
            setTimeout(function () {
              window.location.replace(
                'itms-apps://apps.apple.com/us/app/life-fitness-connect-app/6468623712'
              )
            }, 1000)
          }
          return (
            <>
              {isOSDoReload && (
                <TemporaryBanner
                  onClick={() => console.log('!!')}
                  icon={'exclamation triangle'}
                  header={i18next.t('not_working') + '?'}
                  text={i18next.t('try_reloading_the_page')}
                />
              )}
              <LoadingMessage
                text={i18next.t('redirecting_to') + ' AppStore'}
              />
            </>
          )
        }
      } else if (isAndroid) {
        if (!redirectFrom) {
          if (gymLinkCode === '') {
            window.location.replace(
              'https://play.google.com/store/apps/details?id=com.lifefitness.connect'
            )
          }
          return redirectView()
        } else {
          gymLinkCode === ''
            ? window.location.replace(
                'https://play.google.com/store/apps/details?id=com.lifefitness.connect'
              )
            : window.location.replace(
                'https://play.google.com/store/apps/details?id=com.lifefitness.connect&referrer=' +
                  gymLinkCode
              )
          return (
            <LoadingMessage
              text={i18next.t('redirecting_to') + ' Google play'}
            />
          )
        }
      } else {
        return faultyPlatform()
      }
    } else {
      return faultyPlatform()
    }
  } else if (ongoing === 'fetching') {
    return <LoadingMessage text={i18next.t('fetching')}></LoadingMessage>
  } else {
    return (
      <div style={{ display: 'flex', flex: '1', justifyContent: 'center' }}>
        <Header as="h2" icon>
          {i18next.t('something_went_wrong')}
          <Header.Subheader>
            {i18next.t('please_contact_your_gym_for_further_assistance')}
          </Header.Subheader>
        </Header>
      </div>
    )
  }
}

GymInviteLink.propTypes = {
  getSite: PropTypes.func.isRequired,
  ongoing: PropTypes.string.isRequired,
  site: PropTypes.object,
  gymLinkCode: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  redirectToAppStore: PropTypes.bool,
}
