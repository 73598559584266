import de from 'i18n/locale/de/de.json'
import de_global_exercises from 'i18n/locale/de/de_global_exercises.json'
import de_global_programs from 'i18n/locale/de/de_global_programs.json'
import de_countries from 'i18n/locale/de/de_countries.json'

export default {
  ...de,
  ...de_global_exercises,
  ...de_global_programs,
  ...de_countries,
}
