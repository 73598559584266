import ru from 'i18n/locale/ru/ru.json'
import ru_global_exercises from 'i18n/locale/ru/ru_global_exercises.json'
import ru_global_programs from 'i18n/locale/ru/ru_global_programs.json'
import en_countries from 'i18n/locale/en/en_countries.json'

export default {
  ...ru,
  ...ru_global_exercises,
  ...ru_global_programs,
  ...en_countries,
}
