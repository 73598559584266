import tr from 'i18n/locale/tr/tr.json'
import tr_global_exercises from 'i18n/locale/tr/tr_global_exercises.json'
import tr_global_programs from 'i18n/locale/tr/tr_global_programs.json'
import en_countries from 'i18n/locale/en/en_countries.json'

export default {
  ...tr,
  ...tr_global_exercises,
  ...tr_global_programs,
  ...en_countries,
}
