import pl from 'i18n/locale/pl/pl.json'
import pl_global_exercises from 'i18n/locale/pl/pl_global_exercises.json'
import pl_global_programs from 'i18n/locale/pl/pl_global_programs.json'
import en_countries from 'i18n/locale/en/en_countries.json'

export default {
  ...pl,
  ...pl_global_exercises,
  ...pl_global_programs,
  ...en_countries,
}
