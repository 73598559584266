import eu from 'i18n/locale/eu/eu.json'
import eu_global_exercises from 'i18n/locale/eu/eu_global_exercises.json'
import eu_global_programs from 'i18n/locale/eu/eu_global_programs.json'
import en_countries from 'i18n/locale/en/en_countries.json'

export default {
  ...eu,
  ...eu_global_exercises,
  ...eu_global_programs,
  ...en_countries,
}
